<template>
  <div class="no-p">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />
    <EmployeeForm
      v-if="!isLoading"
      :employeeData="employee"
      :imageSrc="imageSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdateEmployee="addOrUpdateEmployee()"
    />
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeForm from "@/components/employees/employees/EmployeeForm.vue";
import Employee from "@/models/employees/employees/Employee";
import createToastMixin from "@/utils/create-toast-mixin";
import { BASE_URL } from "@/utils/constants";

export default {
  name: "EmployeeEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeForm,
  },
  computed: {
    // ...mapGetters(["employeeToken"]),
    imageSrc: function () {
      if (this.employee.employeeImagePath) {
        return BASE_URL + this.employee.employeeImagePath;
      }
      return this.employee.defaultImg;
    },
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employee: new Employee(),
    };
  },
  methods: {
    async getEmployeeDetails() {
      this.isLoading = true;
      this.employee.employeeToken = this.$route.params.employeeToken;
      try {
        let response = await this.employee.getEmployeeDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.employee.fillData(response.data.employeeData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "Employees" }).catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "Employees" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployee() {
      this.isLoading = true;
      try {
        let response = await this.employee.addOrUpdateEmployee(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push("/employees");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEmployeeDetails();
  },
};
</script>
